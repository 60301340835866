import { useLayoutEffect, useState } from "react"
import history from "@history"
import { Router } from "react-router-dom"
const debug = process.env.NODE_ENV === "development" && false

function BrowserRouter({ basename, children, window }) {
	const [state, setState] = useState({
		action: history.action,
		location: history.location,
	})

	debug && console.log("<BrowserRouter> -state.location.pathname:", state.location.pathname, children)

	useLayoutEffect(() => history.listen(setState), [history])

	return (
		<Router basename={basename} location={state.location} navigationType={state.action} navigator={history}>
			{children}
		</Router>
	)
}

export default BrowserRouter
