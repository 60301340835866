import IconButton from "@mui/material/IconButton"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { useSelector } from "react-redux"
import format from "date-fns/format"
import { useParams } from "react-router-dom"
import { useContext } from "react"
import FuseSvgIcon from "@fuse/core/FuseSvgIcon"
import { lighten } from "@mui/material/styles"
import Box from "@mui/material/Box"
import { selectContactById } from "../../store/contactsSlice"
import { selectChatById } from "../../store/chatsSlice"
import ContactAvatar from "../../ContactAvatar"
import { ChatAppContext } from "../../OncoApp"

function ContactSidebar(props) {
	const { setContactSidebarOpen } = useContext(ChatAppContext)
	const routeParams = useParams()
	const chatId = routeParams.id

	const chat = useSelector((state) => selectChatById(state, chatId)) // added test

	if (!chat) {
		return null
	}

	return (
		<div className="flex flex-col flex-auto h-full">
			<Box
				className="border-b-1"
				sx={{
					backgroundColor: (theme) => (theme.palette.mode === "light" ? lighten(theme.palette.background.default, 0.4) : lighten(theme.palette.background.default, 0.02)),
				}}>
				<Toolbar className="flex items-center px-4">
					<IconButton onClick={() => setContactSidebarOpen(false)} color="inherit" size="large">
						<FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
					</IconButton>
					<Typography className="px-4 font-medium text-16" color="inherit" variant="subtitle1">
						Chat information
					</Typography>
				</Toolbar>
			</Box>

			<div className="w-full p-12">
				<Typography className="mt-40 text-16 font-medium">Details</Typography>

				<div className="mt-16">
					<Typography className="text-14 font-medium" color="text.secondary">
						Title
					</Typography>
					<Typography>{chat.title}</Typography>
				</div>

				<div className="mt-16">
					<Typography className="text-14 font-medium" color="text.secondary">
						session_id
					</Typography>
					<Typography>{chat.id}</Typography>
				</div>

				<div className="mt-16">
					<Typography className="text-14 font-medium" color="text.secondary">
						user_id
					</Typography>
					<Typography>{chat.contactId}</Typography>
				</div>
			</div>
		</div>
	)
}

export default ContactSidebar
