import { lighten, styled } from "@mui/material/styles"
import { motion } from "framer-motion"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import Button from "@mui/material/Button"
import FuseSvgIcon from "@fuse/core/FuseSvgIcon"
import { useContext, useState, useEffect, useRef } from "react"
import InputBase from "@mui/material/InputBase"
import { useDispatch, useSelector } from "react-redux"
import { useParams, useNavigate } from "react-router-dom"
import Paper from "@mui/material/Paper"
import { getChat, selectChat, sendMessage, sendQuestion } from "./store/chatSlice"
import { ChatAppContext } from "./OncoApp"

const ChatFirstScreen = () => {
	const dispatch = useDispatch()
	const { setMainSidebarOpen } = useContext(ChatAppContext)
	const [isSending, setIsSending] = useState(false) //
	const [messageText, setMessageText] = useState("")
	const routeParams = useParams()
	const navigate = useNavigate()
	const chatId = routeParams.id

	function onInputChange(ev) {
		setMessageText(ev.target.value)
	}

	function onMessageSubmit(ev) {
		ev.preventDefault()
		if (messageText.trim() === "") return

		setIsSending(true)
		dispatch(
			sendMessage({
				messageText,
				chatId,
			})
		).then((result) => {
			setMessageText("")
			// Navigate to newly created chat
			navigate(result.payload.chatId)
			// call ai api
			dispatch(sendQuestion(result.payload)).then(() => {
				setIsSending(false)
			})
		})
	}

	return (
		<div className="flex flex-col flex-1 items-center justify-center w-full p-24">
			<FuseSvgIcon className="icon-size-128 mb-16" color="disabled">
				heroicons-outline:chat
			</FuseSvgIcon>
			<Typography className="hidden md:flex text-20 font-semibold tracking-tight text-secondary" color="text.secondary">
				Select a conversation or start a new chat
			</Typography>
			<Typography className="hidden md:flex text-12 font-thin tracking-tight text-secondary" color="text.secondary">
				Onco Hub AI può commettere errori
			</Typography>

			<Button variant="contained" color="secondary" className="flex md:hidden" onClick={() => setMainSidebarOpen(true)}>
				Select a conversation or start a new chat
			</Button>

			<Paper
				square
				component="form"
				onSubmit={onMessageSubmit}
				className="absolute border-t-1 bottom-0 right-0 left-0 py-16 px-16"
				sx={{
					backgroundColor: (theme) => (theme.palette.mode === "light" ? lighten(theme.palette.background.default, 0.4) : lighten(theme.palette.background.default, 0.02)),
				}}>
				<div className="flex items-center relative">
					<InputBase autoFocus={false} id="message-input" className="flex-1 flex grow shrink-0 h-44 mx-8 px-16 border-2 rounded-full" placeholder="Type your question here" onChange={onInputChange} value={messageText} sx={{ backgroundColor: "background.paper" }} />
					<IconButton
						component={motion.button}
						type="submit"
						size="large"
						animate={isSending ? { rotate: 360 } : { rotate: 0 }} // Rotate only when isSending is true
						transition={isSending ? { repeat: Infinity, duration: 1, ease: "linear" } : { duration: 0 }} // Stop rotation when isSending is false
					>
						<FuseSvgIcon className="rotate-90" color="action" size={30}>
							{isSending ? "heroicons-outline:stop" : "heroicons-outline:paper-airplane"}
						</FuseSvgIcon>
					</IconButton>
				</div>
			</Paper>
		</div>
	)
}

export default ChatFirstScreen
