import { lighten, styled } from "@mui/material/styles"
import { motion } from "framer-motion"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import clsx from "clsx"
import formatDistanceToNow from "date-fns/formatDistanceToNow"
import { useContext, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import InputBase from "@mui/material/InputBase"
import Paper from "@mui/material/Paper"
import FuseSvgIcon from "@fuse/core/FuseSvgIcon"
import Toolbar from "@mui/material/Toolbar"
import { useParams, useLocation } from "react-router-dom"
import Box from "@mui/material/Box"
import { setCollectionName, selectCollectionName } from "../store/collectionSlice"
import { getChat, selectChat, sendMessage, sendQuestion } from "../store/chatSlice"
import { selectChatById } from "../store/chatsSlice"
import { selectUser } from "app/store/userSlice"
//import CollectionAvatar from "../CollectionAvatar"
import ChatMoreMenu from "./ChatMoreMenu"
import { ChatAppContext } from "../OncoApp"

const StyledMessageRow = styled("div")(({ theme }) => ({
	"&.contact": {
		"& .bubble": {
			backgroundColor: theme.palette.secondary.light,
			color: theme.palette.secondary.contrastText,
			borderTopLeftRadius: 5,
			borderBottomLeftRadius: 5,
			borderTopRightRadius: 20,
			borderBottomRightRadius: 20,
			"& .time": {
				marginLeft: 12,
			},
		},
		"&.first-of-group": {
			"& .bubble": {
				borderTopLeftRadius: 20,
			},
		},
		"&.last-of-group": {
			"& .bubble": {
				borderBottomLeftRadius: 20,
			},
		},
	},
	"&.me": {
		paddingLeft: 40,

		"& .bubble": {
			marginLeft: "auto",
			backgroundColor: theme.palette.primary.light,
			color: theme.palette.primary.contrastText,
			borderTopLeftRadius: 20,
			borderBottomLeftRadius: 20,
			borderTopRightRadius: 5,
			borderBottomRightRadius: 5,
			"& .time": {
				justifyContent: "flex-end",
				right: 0,
				marginRight: 12,
			},
		},
		"&.first-of-group": {
			"& .bubble": {
				borderTopRightRadius: 20,
			},
		},

		"&.last-of-group": {
			"& .bubble": {
				borderBottomRightRadius: 20,
			},
		},
	},
	"&.contact + .me, &.me + .contact": {
		paddingTop: 20,
		marginTop: 20,
	},
	"&.first-of-group": {
		"& .bubble": {
			borderTopLeftRadius: 20,
			paddingTop: 13,
		},
	},
	"&.last-of-group": {
		"& .bubble": {
			borderBottomLeftRadius: 20,
			paddingBottom: 13,
			"& .time": {
				display: "flex",
			},
		},
	},
}))

function Chat(props) {
	const { setMainSidebarOpen, setContactSidebarOpen } = useContext(ChatAppContext)
	const dispatch = useDispatch()
	const collection = useSelector(selectCollectionName)
	const chat = useSelector(selectChat)
	const user = useSelector(selectUser)
	const routeParams = useParams()
	const location = useLocation()
	const chatId = routeParams.id
	const selectedChat = useSelector((state) => selectChatById(state, chatId))
	const chatRef = useRef(null)
	const [messageText, setMessageText] = useState("")
	const [isRecording, setIsRecording] = useState(false)
	const [transcript, setTranscript] = useState("")
	const [apiResponse, setApiResponse] = useState("")
	const [displayedText, setDisplayedText] = useState("")
	const [isSending, setIsSending] = useState(false) // To track API request status
	const [apiError, setApiError] = useState(null) // To track API errors if any

	useEffect(() => {
		// Split the path by "/" and extract the token based on its position
		const tokenizedPath = location.pathname.split("/")
		const collectionName = tokenizedPath[3]

		dispatch(setCollectionName(collectionName))
		dispatch(getChat(chatId))
	}, [chatId, collection, dispatch])

	useEffect(() => {
		if (chat) {
			setTimeout(scrollToBottom)
		}
	}, [chat])

	function scrollToBottom() {
		if (!chatRef.current) {
			return
		}
		chatRef.current.scrollTo({
			top: chatRef.current.scrollHeight,
			behavior: "smooth",
		})
	}

	function isFirstMessageOfGroup(item, i) {
		return i === 0 || (chat[i - 1] && chat[i - 1].contactId !== item.contactId)
	}

	function isLastMessageOfGroup(item, i) {
		return i === chat.length - 1 || (chat[i + 1] && chat[i + 1].contactId !== item.contactId)
	}

	function onInputChange(ev) {
		setMessageText(ev.target.value)
	}

	function onMessageSubmit(ev) {
		ev.preventDefault()
		if (messageText === "") {
			return
		}
		setIsSending(true)
		dispatch(
			sendMessage({
				messageText,
				chatId,
			})
		).then((result) => {
			setMessageText("")
			dispatch(sendQuestion(result.payload)).then(() => {
				setIsSending(false)
			})
		})
	}

	// Function to parse each link from the sources
	const parseLink = (source) => {
		const urlMatch = source.match(/href=["']?([^"'\s>]+)["']?/) // Matches both href="URL" and href=URL
		const textMatch = source.match(/>([^<]+)</) // Extract link text

		if (urlMatch && textMatch) {
			return { url: urlMatch[1], text: textMatch[1] }
		}
		return null
	}

	if (!user || !chat || !selectedChat) {
		return null
	}

	return (
		<>
			<Box
				className="w-full border-b-1"
				sx={{
					backgroundColor: (theme) => (theme.palette.mode === "light" ? lighten(theme.palette.background.default, 0.4) : lighten(theme.palette.background.default, 0.02)),
				}}>
				<Toolbar className="flex items-center justify-between px-16 w-full">
					<div className="flex items-center">
						<IconButton aria-label="Open drawer" onClick={() => setMainSidebarOpen(true)} className="flex lg:hidden" size="large">
							<FuseSvgIcon>heroicons-outline:chat</FuseSvgIcon>
						</IconButton>
						<div
							className="flex items-center cursor-pointer"
							onClick={() => {
								setContactSidebarOpen(true)
							}}
							onKeyDown={() => setContactSidebarOpen(true)}
							role="button"
							tabIndex={0}>
							{/*<CollectionAvatar className="relative mx-8" contact={selectedChat} />*/}
							<Typography color="inherit" className="text-16 font-semibold px-4">
								{selectedChat.title}
							</Typography>
						</div>
					</div>
					<ChatMoreMenu className="-mx-8" />
				</Toolbar>
			</Box>

			<div className="flex flex-auto h-full min-h-0 w-full">
				<div className={clsx("flex flex-1 z-10 flex-col relative", props.className)}>
					<div ref={chatRef} className="flex flex-1 flex-col overflow-y-auto">
						{chat?.length > 0 && (
							<div className="flex flex-col pt-16 px-16 pb-40">
								{chat.map((item, i) => {
									return (
										<StyledMessageRow key={i} className={clsx("flex flex-col grow-0 shrink-0 items-start justify-end relative px-16 pb-4", item.contactId === user.id ? "me" : "contact", { "first-of-group": isFirstMessageOfGroup(item, i) }, { "last-of-group": isLastMessageOfGroup(item, i) }, i + 1 === chat.length && "pb-96")}>
											<div className="bubble flex relative items-center justify-center p-12 max-w-full">
												<div className="leading-tight whitespace-pre-wrap">
													{item.value}
													<br />
													{item.sources && item.sources.length > 0 && (
														<Box sx={{ marginTop: "10px" }}>
															<Typography variant="subtitle2">Useful links:</Typography>
															<Box component="ul" sx={{ paddingLeft: 2, listStyleType: "disc" }}>
																{item.sources.map((source, index) => {
																	const link = parseLink(source)
																	return link ? (
																		<Box component="li" key={index} sx={{ marginBottom: "8px" }}>
																			<a href={link.url} target="_blank" rel="noopener noreferrer">
																				{link.text}
																			</a>
																		</Box>
																	) : null
																})}
															</Box>
														</Box>
													)}
												</div>

												<Typography className="time absolute hidden w-full text-11 mt-8 -mb-24 ltr:left-0 rtl:right-0 bottom-0 whitespace-nowrap" color="text.secondary">
													{formatDistanceToNow(new Date(item.createdAt), { addSuffix: true })}
												</Typography>
											</div>
										</StyledMessageRow>
									)
								})}
							</div>
						)}
					</div>
					{chat && (
						<Paper
							square
							component="form"
							onSubmit={onMessageSubmit}
							className="absolute border-t-1 bottom-0 right-0 left-0 py-16 px-16"
							sx={{
								backgroundColor: (theme) => (theme.palette.mode === "light" ? lighten(theme.palette.background.default, 0.4) : lighten(theme.palette.background.default, 0.02)),
							}}>
							<div className="flex items-center relative">
								<InputBase autoFocus={false} id="message-input" className="flex-1 flex grow shrink-0 h-44 mx-8 px-16 border-2 rounded-full" placeholder="Type your question here" onChange={onInputChange} value={messageText} sx={{ backgroundColor: "background.paper" }} />
								<IconButton
									component={motion.button}
									type="submit"
									size="large"
									animate={isSending ? { rotate: 360 } : { rotate: 0 }} // Rotate only when isSending is true
									transition={isSending ? { repeat: Infinity, duration: 1, ease: "linear" } : { duration: 0 }} // Stop rotation when isSending is false
								>
									<FuseSvgIcon className="rotate-90" color="action" size={30}>
										{isSending ? "heroicons-outline:stop" : "heroicons-outline:paper-airplane"}
									</FuseSvgIcon>
								</IconButton>
							</div>
						</Paper>
					)}
				</div>
			</div>
		</>
	)
}

export default Chat
