import FuseNavigation from "@fuse/core/FuseNavigation"
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery"
import clsx from "clsx"
import { memo, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectNavigation } from "app/store/fuse/navigationSlice"
import { navbarCloseMobile } from "app/store/fuse/navbarSlice"

const debug = process.env.NODE_ENV === "development" && false

function Navigation(props) {
	const navigation = useSelector(selectNavigation)
	const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"))
	const dispatch = useDispatch()

	return useMemo(() => {
		debug && console.log("<Navigation> navigation:", navigation)

		function handleItemClick(item) {
			if (isMobile) {
				dispatch(navbarCloseMobile())
			}
		}

		return <FuseNavigation className={clsx("navigation", props.className)} navigation={navigation} layout={props.layout} dense={props.dense} active={props.active} onItemClick={handleItemClick} />
	}, [dispatch, isMobile, navigation, props.active, props.className, props.dense, props.layout])
}

Navigation.defaultProps = {
	layout: "vertical",
}

export default memo(Navigation)
