import { styled } from "@mui/material/styles"
import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import withReducer from "app/store/withReducer"
import { createContext, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Outlet, useLocation, useParams } from "react-router-dom"
import FusePageSimple from "@fuse/core/FusePageSimple"
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery"
import MainSidebar from "./sidebars/main/MainSidebar"
import ContactSidebar from "./sidebars/contact/ContactSidebar"
import UserSidebar from "./sidebars/user/UserSidebar"
import reducer from "./store"
//import { selectUser } from "app/store/userSlice"
import { setCollectionName } from "./store/collectionSlice"
import { getUserData } from "./store/userSlice"
import { getContacts } from "./store/contactsSlice"
import { getChats } from "./store/chatsSlice"

const debug = process.env.NODE_ENV === "development" && false

const drawerWidth = 400

export const ChatAppContext = createContext({})

const Root = styled(FusePageSimple)(({ theme }) => ({
	"& .FusePageSimple-content": {
		display: "flex",
		flexDirection: "column",
		flex: "1 1 100%",
		height: "100%",
	},
}))

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
	"& .MuiDrawer-paper": {
		width: drawerWidth,
		maxWidth: "100%",
		overflow: "hidden",
		[theme.breakpoints.up("md")]: {
			position: "relative",
		},
	},
}))

function OncoApp(props) {
	const dispatch = useDispatch()
	//const routeParams = useParams()
	//const user = useSelector(selectUser)
	const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"))
	const [mainSidebarOpen, setMainSidebarOpen] = useState(!isMobile)
	const [contactSidebarOpen, setContactSidebarOpen] = useState(false)
	const [userSidebarOpen, setUserSidebarOpen] = useState(false)
	const location = useLocation()

	useEffect(() => {
		//debug && console.log("<OncoApp> useEffect(1) -routeParams:", routeParams)

		// Split the path by "/" and extract the token based on its position
		const tokenizedPath = location.pathname.split("/")
		const collectionName = tokenizedPath[3]

		dispatch(setCollectionName(collectionName))
		dispatch(getUserData())
		dispatch(getContacts())
		dispatch(getChats())
	}, [dispatch, location])

	useEffect(() => {
		//debug && console.log("<OncoApp> useEffect(2) -isMobile:", isMobile)
		setMainSidebarOpen(!isMobile)
	}, [isMobile])

	useEffect(() => {
		debug && console.log("<OncoApp> useEffect(3) -location:", location)
		if (isMobile) {
			setMainSidebarOpen(false)
		}
	}, [location, isMobile])

	return (
		<ChatAppContext.Provider value={{ setMainSidebarOpen, setContactSidebarOpen, setUserSidebarOpen }}>
			<Root
				content={<Outlet />}
				leftSidebarContent={<MainSidebar />}
				leftSidebarOpen={mainSidebarOpen}
				leftSidebarOnClose={() => {
					setMainSidebarOpen(false)
				}}
				leftSidebarWidth={400}
				rightSidebarContent={<ContactSidebar />}
				rightSidebarOpen={contactSidebarOpen}
				rightSidebarOnClose={() => {
					setContactSidebarOpen(false)
				}}
				rightSidebarWidth={400}
				scroll="content"
			/>
			<StyledSwipeableDrawer
				className="h-full absolute z-9999"
				variant="temporary"
				anchor="left"
				open={userSidebarOpen}
				onOpen={(ev) => {}}
				onClose={() => setUserSidebarOpen(false)}
				classes={{
					paper: "absolute left-0",
				}}
				style={{ position: "absolute" }}
				ModalProps={{
					keepMounted: false,
					disablePortal: true,
					BackdropProps: {
						classes: {
							root: "absolute",
						},
					},
				}}>
				<UserSidebar />
			</StyledSwipeableDrawer>
		</ChatAppContext.Provider>
	)
}

export default withReducer("oncoApp", reducer)(OncoApp)
