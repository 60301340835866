import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const getContacts = createAsyncThunk("oncoApp/contacts/getContacts", async (params) => {
	const response = await axios.get("/api/chat/contacts", { params })

	const data = await response.data

	return data
})

const contactsAdapter = createEntityAdapter({})

export const { selectAll: selectContacts, selectById: selectContactById } = contactsAdapter.getSelectors((state) => state.oncoApp.contacts)

const contactsSlice = createSlice({
	name: "oncoApp/contacts",
	initialState: contactsAdapter.getInitialState({}),
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getContacts.fulfilled, contactsAdapter.setAll)
	},
})

export default contactsSlice.reducer
