import { lazy } from "react"
import authRoles from "src/app/auth/authRoles"
import Chat from "./chat/Chat"
import ChatFirstScreen from "./ChatFirstScreen"
const OncoApp = lazy(() => import("./OncoApp"))

const OncoAppConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: "apps/hub/oncolung",
			auth: authRoles.staff,
			element: <OncoApp />,
			children: [
				{
					path: "",
					element: <ChatFirstScreen />,
				},
				{
					path: ":id",
					element: <Chat />,
				},
			],
		},
		{
			path: "apps/hub/oncouro",
			auth: authRoles.staff,
			element: <OncoApp />,
			children: [
				{
					path: "",
					element: <ChatFirstScreen />,
				},
				{
					path: ":id",
					element: <Chat />,
				},
			],
		},
		{
			path: "apps/hub/oncobreast",
			auth: authRoles.staff,
			element: <OncoApp />,
			children: [
				{
					path: "",
					element: <ChatFirstScreen />,
				},
				{
					path: ":id",
					element: <Chat />,
				},
			],
		},
		{
			path: "apps/hub/oncogyne",
			auth: authRoles.staff,
			element: <OncoApp />,
			children: [
				{
					path: "",
					element: <ChatFirstScreen />,
				},
				{
					path: ":id",
					element: <Chat />,
				},
			],
		},
		{
			path: "apps/hub/oncogastro",
			auth: authRoles.staff,
			element: <OncoApp />,
			children: [
				{
					path: "",
					element: <ChatFirstScreen />,
				},
				{
					path: ":id",
					element: <Chat />,
				},
			],
		},
		{
			path: "apps/hub/onconeuro",
			auth: authRoles.staff,
			element: <OncoApp />,
			children: [
				{
					path: "",
					element: <ChatFirstScreen />,
				},
				{
					path: ":id",
					element: <Chat />,
				},
			],
		},
		{
			path: "apps/hub/oncodermo",
			auth: authRoles.staff,
			element: <OncoApp />,
			children: [
				{
					path: "",
					element: <ChatFirstScreen />,
				},
				{
					path: ":id",
					element: <Chat />,
				},
			],
		},
		{
			path: "apps/hub/oncoemato",
			auth: authRoles.staff,
			element: <OncoApp />,
			children: [
				{
					path: "",
					element: <ChatFirstScreen />,
				},
				{
					path: ":id",
					element: <Chat />,
				},
			],
		},
		{
			path: "apps/hub/oncologyai",
			auth: authRoles.staff,
			element: <OncoApp />,
			children: [
				{
					path: "",
					element: <ChatFirstScreen />,
				},
				{
					path: ":id",
					element: <Chat />,
				},
			],
		},
	],
}

export default OncoAppConfig
