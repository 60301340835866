import { createSlice } from "@reduxjs/toolkit"

// Initial state with status tracking
const initialState = {
	name: null,
	status: "idle",
}

const collectionSlice = createSlice({
	name: "oncoApp/collection",
	initialState,
	reducers: {
		setCollectionName: (state, action) => {
			state.name = action.payload
		},
	},
	extraReducers: (builder) => {},
})

export const selectCollectionName = (state) => state.oncoApp.collection.name

export const { setCollectionName } = collectionSlice.actions

export default collectionSlice.reducer
