import FuseScrollbars from "@fuse/core/FuseScrollbars"
import { styled, useTheme } from "@mui/material/styles"
import FuseSettings from "@fuse/core/FuseSettings"
import Button from "@mui/material/Button"
//import { red } from '@mui/material/colors'
import Dialog from "@mui/material/Dialog"
import IconButton from "@mui/material/IconButton"
import Slide from "@mui/material/Slide"
import Typography from "@mui/material/Typography"
import { forwardRef, memo, useState } from "react"
import FuseThemeSchemes from "@fuse/core/FuseThemeSchemes"
import { useSwipeable } from "react-swipeable"
import FuseSvgIcon from "@fuse/core/FuseSvgIcon"
import themesConfig from "app/configs/themesConfig"
import { changeFuseTheme } from "app/store/fuse/settingsSlice"
import { useDispatch, useSelector } from "react-redux"
import FuseSettingsViewerDialog from "./FuseSettingsViewerDialog"
import { selectUser } from "app/store/userSlice"

const StyledDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialog-paper": {
		position: "fixed",
		width: 380,
		maxWidth: "90vw",
		backgroundColor: theme.palette.background.paper,
		top: 0,
		height: "100%",
		minHeight: "100%",
		bottom: 0,
		right: 0,
		margin: 0,
		zIndex: 1000,
		borderRadius: 0,
	},
}))

const Transition = forwardRef(function Transition(props, ref) {
	const theme = useTheme()
	return <Slide direction={theme.direction === "ltr" ? "left" : "right"} ref={ref} {...props} />
})

function SettingsPanel() {
	const user = useSelector(selectUser)
	const theme = useTheme()
	const [open, setOpen] = useState(false)
	const dispatch = useDispatch()

	const handlerOptions = {
		onSwipedLeft: () => {
			return open && theme.direction === "rtl" && handleClose()
		},
		onSwipedRight: () => {
			return open && theme.direction === "ltr" && handleClose()
		},
	}

	const settingsHandlers = useSwipeable(handlerOptions)
	const shemesHandlers = useSwipeable(handlerOptions)

	const handleOpen = (panelId) => {
		setOpen(panelId)
	}

	const handleClose = () => {
		setOpen(false)
	}
	//{(!user.role || (Array.isArray(user.role) && user.role.length === 0)) && "Guest"}
	return (
		<>
			{user.role && user.role === "admin" && (
				<IconButton className="w-40 h-40" onClick={(ev) => handleOpen("settings")} size="large">
					<FuseSvgIcon>heroicons-outline:cog</FuseSvgIcon>
				</IconButton>
			)}

			<IconButton className="w-40 h-40" onClick={(ev) => handleOpen("schemes")} size="large">
				<FuseSvgIcon>heroicons-outline:color-swatch</FuseSvgIcon>
			</IconButton>

			<StyledDialog
				TransitionComponent={Transition}
				aria-labelledby="settings-panel"
				aria-describedby="settings"
				open={open === "settings"}
				onClose={handleClose}
				BackdropProps={{ invisible: true }}
				classes={{
					paper: "shadow-lg",
				}}
				{...settingsHandlers}>
				<FuseScrollbars className="p-16 sm:p-32">
					<IconButton className="fixed top-0 ltr:right-0 rtl:left-0 z-10" onClick={handleClose} size="large">
						<FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
					</IconButton>

					<Typography className="mb-32 font-semibold" variant="h6">
						Theme Settings
					</Typography>

					<FuseSettings />

					<FuseSettingsViewerDialog className="mt-32" />
				</FuseScrollbars>
			</StyledDialog>
			<StyledDialog
				TransitionComponent={Transition}
				aria-labelledby="schemes-panel"
				aria-describedby="schemes"
				open={open === "schemes"}
				onClose={handleClose}
				BackdropProps={{ invisible: true }}
				classes={{
					paper: "shadow-lg",
				}}
				{...shemesHandlers}>
				<FuseScrollbars className="p-16 sm:p-32">
					<IconButton className="fixed top-0 ltr:right-0 rtl:left-0 z-10" onClick={handleClose} size="large">
						<FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
					</IconButton>

					<Typography className="mb-32" variant="h6">
						Theme Color Schemes
					</Typography>

					<Typography className="mb-24 text-12 italic text-justify" color="text.secondary">
						* Selected color scheme will be applied to all theme layout elements (navbar, toolbar, etc.). You can also select a different color scheme for each layout element at theme settings.
					</Typography>

					<FuseThemeSchemes
						themes={themesConfig}
						onSelect={(_theme) => {
							dispatch(changeFuseTheme(_theme))
						}}
					/>
				</FuseScrollbars>
			</StyledDialog>
		</>
	)
}

export default memo(SettingsPanel)
