const sessionStorageKey = "fuseRedirectUrl"
const debug = process.env.NODE_ENV === "development" && true

export const getSessionRedirectUrl = () => {
	debug && console.log("sessionRedirectUrl -getSessionRedirectUrl: " + window.sessionStorage.getItem(sessionStorageKey))
	return window.sessionStorage.getItem(sessionStorageKey)
}

export const setSessionRedirectUrl = (url) => {
	window.sessionStorage.setItem(sessionStorageKey, url)
	debug && console.log("sessionRedirectUrl -setSessionRedirectUrl: " + window.sessionStorage.getItem(sessionStorageKey))
}

export const resetSessionRedirectUrl = (url) => {
	window.sessionStorage.removeItem(sessionStorageKey)
	debug && console.log("sessionRedirectUrl -resetSessionRedirectUrl: " + window.sessionStorage.getItem(sessionStorageKey))
}
