/**
 * Authorization Roles
 *  =>  role: ['who can see']
 */
const authRoles = {
	admin: ["admin"],
	staff: ["admin", "staff"],
	user: ["admin", "staff", "user"],
	guest: ["admin", "staff", "user", "guest"],
	prospect: ["prospect", ""],
	onlyGuest: [],
}

export default authRoles

/*
oncogyne, oncouro, oncolung, oncogastro, onconeuro, oncodermo, oncoemato, oncobreast, oncologyai

*/
