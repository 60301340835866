import FuseLoading from "@fuse/core/FuseLoading"
import PropTypes from "prop-types"
import { Suspense } from "react"

const debug = process.env.NODE_ENV === "development" && false
let rendering = -1

/**
 * React Suspense defaults
 * For to Avoid Repetition
 */
function FuseSuspense(props) {
	debug && console.log("<FuseSuspense>".padEnd(21) + "%cConstructor() -rendering:", "color: yellow", ++rendering, props.children.props)
	return <Suspense fallback={<FuseLoading {...props.loadingProps} />}>{props.children}</Suspense>
}

FuseSuspense.propTypes = {
	loadingProps: PropTypes.object,
}

FuseSuspense.defaultProps = {
	loadingProps: {
		delay: 0,
	},
}

export default FuseSuspense
