import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
//import axios from "axios"
import axios from "@fuse/axios"
import { selectCollectionName } from "./collectionSlice"
import { getChats } from "./chatsSlice"
const debug = process.env.NODE_ENV === "development" && true

export const getChat = createAsyncThunk("oncoApp/chat/getChat", async (contactId, { dispatch, getState }) => {
	const collection_name = selectCollectionName(getState())

	//debug && console.log({ collection_name, contactId })
	const response = await axios.get(`/api/v1/hub/${collection_name}/chat/${contactId}`)

	const data = await response.data

	return data
})

export const sendMessage = createAsyncThunk("oncoApp/chat/sendMessage", async ({ chatId, contactId, messageText }, { dispatch, getState }) => {
	const { user } = getState()
	const collection_name = selectCollectionName(getState())

	//debug && console.log({ collection_name, chatId, contactId, messageText })
	const response = await axios.post(`/api/v1/hub/${collection_name}/chat`, { chatId, contactId: user.id, value: messageText })

	const data = await response.data
	//debug && console.debug({ data })

	//dispatch(getChats())

	return data
})

export const sendQuestion = createAsyncThunk("oncoApp/chat/sendQuestion", async ({ chatId, value }, { dispatch, getState }) => {
	const { user } = getState()
	const collection_name = selectCollectionName(getState())

	debug && console.debug({ session_id: chatId, user_id: user.id, message: value, collection_name })
	const answer = await axios.post(`/api/v1/hub/oncobot/ai/chat`, { session_id: chatId, user_id: user.id, message: value, collection_name })

	const { response, sources } = await answer.data
	debug && console.debug({ response, sources })

	const output = await axios.post(`/api/v1/hub/${collection_name}/chat`, { chatId, value: response, sources })

	const data = await output.data

	dispatch(getChats())

	return data
})

const chatSlice = createSlice({
	name: "oncoApp/chat",
	initialState: [],
	reducers: {
		removeChat: (state, action) => action.payload,
	},
	extraReducers: (builder) => {
		builder
			//
			.addCase(getChat.fulfilled, (state, action) => action.payload)
			.addCase(sendMessage.fulfilled, (state, action) => [...state, action.payload])
			.addCase(sendQuestion.fulfilled, (state, action) => [...state, action.payload])
	},
})

export const selectChat = ({ oncoApp }) => oncoApp.chat

export default chatSlice.reducer
