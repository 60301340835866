import OncobotDashboardAppConfig from "./oncobot/OncobotDashboardAppConfig"
import AnalyticsDashboardAppConfig from "./analytics/AnalyticsDashboardAppConfig"
import ProjectDashboardAppConfig from "./project/ProjectDashboardAppConfig"
import FinanceDashboardAppConfig from "./finance/FinanceDashboardAppConfig"
import SystemDashboardAppConfig from "./system/SystemDashboardAppConfig"
import CryptoDashboardAppConfig from "./crypto/CryptoDashboardAppConfig"

const dashboardsConfigs = [OncobotDashboardAppConfig, SystemDashboardAppConfig, AnalyticsDashboardAppConfig, ProjectDashboardAppConfig, FinanceDashboardAppConfig, CryptoDashboardAppConfig]

export default dashboardsConfigs
