import { ThemeProvider } from "@mui/material/styles"
import AppBar from "@mui/material/AppBar"
import Hidden from "@mui/material/Hidden"
import Toolbar from "@mui/material/Toolbar"
import clsx from "clsx"
import { memo } from "react"
import { useSelector } from "react-redux"
import { selectFuseCurrentLayoutConfig, selectToolbarTheme } from "app/store/fuse/settingsSlice"
import { selectFuseNavbar } from "app/store/fuse/navbarSlice"
import AdjustFontSize from "../../shared-components/AdjustFontSize"
import FullScreenToggle from "../../shared-components/FullScreenToggle"
import LanguageSwitcher from "../../shared-components/LanguageSwitcher"
import NavigationShortcuts from "../../shared-components/NavigationShortcuts"
import NavbarToggleButton from "../../shared-components/NavbarToggleButton"
import UserMenu from "../../shared-components/UserMenu"
import SettingsPanel from "../../shared-components/SettingsPanel"
import TabsLayout5 from "./TabsLayout5"
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery"
//import NotificationPanelToggleButton from "../../shared-components/notificationPanel/NotificationPanelToggleButton"
//import NavigationSearch from "../../shared-components/NavigationSearch"
//import QuickPanelToggleButton from "../../shared-components/quickPanel/QuickPanelToggleButton"
//import ChatPanelToggleButton from "../../shared-components/chatPanel/ChatPanelToggleButton"

const debug = process.env.NODE_ENV === "development" && false
let rendering = -1

function ToolbarLayout5(props) {
	debug && console.log("<ToolbarLayout5>".padEnd(21) + "%cConstructor()", "color: lightblue", ++rendering, { props })
	const config = useSelector(selectFuseCurrentLayoutConfig)
	const navbar = useSelector(selectFuseNavbar)
	const toolbarTheme = useSelector(selectToolbarTheme)

	const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"))
	debug && console.log("<ToolbarLayout5>  -isMobile: ", isMobile)

	return (
		<ThemeProvider theme={toolbarTheme}>
			<AppBar
				id="fuse-toolbar"
				className={clsx("flex relative z-20 shadow-none", props.className)}
				color="default"
				sx={{
					backgroundColor: (theme) => (theme.palette.mode === "light" ? toolbarTheme.palette.background.paper : toolbarTheme.palette.background.default),
				}}
				position="static">
				<Toolbar className="p-0 min-h-48 md:min-h-48">
					{/* md:min-h-64 */}
					<div className="flex flex-1 px-4">
						{config.navbar.display && config.navbar.position === "left" && (
							<>
								<Hidden lgDown>
									{(config.navbar.style === "style-3" || config.navbar.style === "style-3-dense") && <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />}

									{config.navbar.style === "style-1" && !navbar.open && <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />}
								</Hidden>

								<Hidden lgUp>
									<NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
								</Hidden>
							</>
						)}

						<Hidden lgDown>
							<NavigationShortcuts />
						</Hidden>
					</div>

					<div className="flex items-center px-8 h-full overflow-x-auto">
						{/*<NavigationSearch />*/}
						{/*<Hidden lgUp><ChatPanelToggleButton />/Hidden>*/}
						{/*<QuickPanelToggleButton />*/}
						{/*<NotificationPanelToggleButton />*/}
						<SettingsPanel />
						{isMobile ? null : <FullScreenToggle />}
						<AdjustFontSize />
						<LanguageSwitcher />
						<UserMenu />
					</div>

					{config.navbar.display && config.navbar.position === "right" && (
						<>
							<Hidden lgDown>{!navbar.open && <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />}</Hidden>
							<Hidden lgUp>
								<NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
							</Hidden>
						</>
					)}
				</Toolbar>
				{!isMobile && <TabsLayout5 />}
			</AppBar>
		</ThemeProvider>
	)
}

export default memo(ToolbarLayout5)
