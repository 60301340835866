import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const getUserData = createAsyncThunk("oncoApp/user/getUserData", async () => {
	const response = await axios.get("/api/chat/user")

	const data = await response.data

	return data
})

export const updateUserData = createAsyncThunk("oncoApp/user/updateUserData", async (newData) => {
	const response = await axios.post("/api/chat/user", newData)

	const data = await response.data

	return data
})

const userSlice = createSlice({
	name: "oncoApp/user",
	initialState: null,
	/*extraReducers: {
		[getUserData.fulfilled]: (state, action) => action.payload,
		[updateUserData.fulfilled]: (state, action) => action.payload,
	},*/
	extraReducers: (builder) => {
		builder
			//
			.addCase(getUserData.fulfilled, (state, action) => action.payload)
			.addCase(updateUserData.fulfilled, (state, action) => action.payload)
	},
})

export const selectUser = ({ oncoApp }) => oncoApp.user

export default userSlice.reducer
