import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit"
//import axios from "axios"
import axios from "@fuse/axios"
import { selectCollectionName } from "./collectionSlice"
const debug = process.env.NODE_ENV === "development" && false

/**
 * get the logged in chats list
 * _: [optional] params not in use
 * getState().oncoApp.collection.name works but selectCollectionName(getState()) is even better
 */
export const getChats = createAsyncThunk("oncoApp/chats/getChats", async (_, { getState, rejectWithValue }) => {
	const { user } = getState()
	const collection_name = selectCollectionName(getState())
	debug && console.log({ collection_name })

	//response = await axios.get("/api/chat/chats", { params })
	const response = await axios.get(`/api/v1/hub/${collection_name}/${user.id}/chats`) /*.catch((error) => {
		console.error("Failed to get chats:", error)
		return []
	})*/
	const data = await response.data
	return data
})

const chatsAdapter = createEntityAdapter({})

export const { selectAll: selectChats, selectById: selectChatById } = chatsAdapter.getSelectors((state) => state.oncoApp.chats)

const chatsSlice = createSlice({
	name: "oncoApp/chats",
	initialState: chatsAdapter.getInitialState(),
	extraReducers: (builder) => {
		builder.addCase(getChats.fulfilled, chatsAdapter.setAll)
	},
})

export default chatsSlice.reducer
