import i18next from "i18next"
import { lazy } from "react"
import it from "./i18n/it"
import en from "./i18n/en"
import authRoles from "../../../auth/authRoles"
const OncobotDashboardApp = lazy(() => import("./OncobotDashboardApp"))

i18next.addResourceBundle("en", "oncobotDashboardApp", en)
i18next.addResourceBundle("it", "oncobotDashboardApp", it)

const OncobotDashboardAppConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	auth: authRoles.admin, // ['admin','staff']
	routes: [
		{
			path: "dashboards/oncobot",
			element: <OncobotDashboardApp />,
		},
	],
}

export default OncobotDashboardAppConfig
