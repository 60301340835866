import FuseScrollbars from "@fuse/core/FuseScrollbars"
import FuseUtils from "@fuse/utils"
import Input from "@mui/material/Input"
import List from "@mui/material/List"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import { motion } from "framer-motion"
import { useContext, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import clsx from "clsx"
import FuseSvgIcon from "@fuse/core/FuseSvgIcon"
import Box from "@mui/material/Box"
import { lighten } from "@mui/material/styles"
import ChatListItem from "./ChatListItem"
import { getChat } from "../../store/chatSlice"
import NavLinkAdapter from "@fuse/core/NavLinkAdapter"
import { selectChats } from "../../store/chatsSlice"
import CollectionAvatar from "../../CollectionAvatar"
import MainSidebarMoreMenu from "./MainSidebarMoreMenu"
import { ChatAppContext } from "../../OncoApp"

function MainSidebar(props) {
	const { setUserSidebarOpen } = useContext(ChatAppContext)
	const dispatch = useDispatch()
	const chats = useSelector(selectChats)
	const [searchText, setSearchText] = useState("")

	function handleSearchText(event) {
		setSearchText(event.target.value)
	}

	const item = {
		hidden: { opacity: 0, y: 20 },
		show: { opacity: 1, y: 0 },
	}
	return (
		<div className="flex flex-col flex-auto h-full">
			<Box
				className="py-16 px-16 border-b-1"
				sx={{
					backgroundColor: (theme) => (theme.palette.mode === "light" ? lighten(theme.palette.background.default, 0.4) : lighten(theme.palette.background.default, 0.02)),
				}}>
				{
					<motion.div variants={item}>
						<div className="flex p-4 items-center w-full px-8 py-4">
							<FuseSvgIcon className="mr-4 mb-20" size={24}>
								heroicons-outline:chat
							</FuseSvgIcon>
							<Typography className="font-medium text-20 px-6 py-6 mb-20 decoration-none" color="secondary.main" component={NavLinkAdapter} to={""} style={{ textDecoration: "none" }}>
								Start a new chat
							</Typography>
						</div>
					</motion.div>
				}

				{useMemo(
					() => (
						<Paper className="flex p-4 items-center w-full px-16 py-4 border-1 h-40 rounded-full shadow-none">
							<FuseSvgIcon color="action" size={20}>
								heroicons-solid:search
							</FuseSvgIcon>

							<Input
								placeholder="Search or start new chat"
								className="flex flex-1 px-8"
								disableUnderline
								fullWidth
								value={searchText}
								inputProps={{
									"aria-label": "Search",
								}}
								onChange={handleSearchText}
							/>
						</Paper>
					),
					[searchText]
				)}
			</Box>

			<FuseScrollbars className="overflow-y-auto flex-1">
				<List className="w-full -mt-8">
					{useMemo(() => {
						function getFilteredArray(arr, _searchText) {
							if (_searchText.length === 0) {
								return arr
							}
							return FuseUtils.filterArrayByString(arr, _searchText)
						}

						const filteredChats = getFilteredArray([...chats], searchText)

						const container = {
							show: {
								transition: {
									staggerChildren: 0.1,
								},
							},
						}

						const item = {
							hidden: { opacity: 0, y: 20 },
							show: { opacity: 1, y: 0 },
						}

						return (
							<motion.div className="flex flex-col shrink-0" variants={container} initial="hidden" animate="show">
								{filteredChats.map((chat, index) => (
									<motion.div variants={item} key={chat.id}>
										<div className={clsx("border-b-1")}>
											<ChatListItem chatFound chat={chat} onContactClick={(chatId) => dispatch(getChat(chatId))} />
										</div>
									</motion.div>
								))}
							</motion.div>
						)
					}, [chats, searchText, dispatch])}
				</List>
			</FuseScrollbars>
		</div>
	)
}

export default MainSidebar
