const locale = {
	APPLICATIONS: "Applicazioni",
	DASHBOARDS: "Dashboard",
	ONCOLUNG: "OncoLung",
	ONCOURO: "OncoUro",
	ONCOBREAST: "OncoBreast",
	ONCOGYNE: "OncoGyne",
	ONCOGASTRO: "OncoGastro",
	ONCONEURO: "OncoNeuro",
	ONCODERMO: "OncoDermo",
	ONCOEMATO: "OncoEmato",
	ONCOLOGYAI: "Oncologyai",
	CONTACTS: "Contatti",
}

export default locale
