const locale = {
	SIGNIN_MESSAGE: "Prego autenticarsi con le credenziali fornite",
	//SIGNIN_MESSAGE: "Prego autenticarsi con le credenziali fornite oppure utilizzare un account Google aziendale",
	EMAIL_OR_SOCIAL: "OPPURE",
	WELCOME: "Ciao",
	SIGNON_MESSAGE: "Ci spiace...",
	DATE_TIME: "{{value, dateTime}}",
	SIGNON_1: "Ti sei autenticato con successo attraverso",
	SIGNON_2: "Tuttavia il tuo account non risulta attivo nel nostro sistema.",
	SIGNON_3: "Se è la prima volta che accedi oppure ritieni di averne i requisiti ti preghiamo di contattare il tuo rappresentante tecnico/commerciale per farti autorizzare.",
	SIGNON_4: "Il tuo tentativo è stato comunque registrato e un addetto potrebbe presto intervenire per consentirti l'accesso.",
	SIGNON_5: "Riprova tra qualche minuto...",
	SIGNON_6: "Grazie!",
	SIGNUP_MESSAGE: "Ti sei autenticato con successo. Per favore confermati",
}

export default locale
