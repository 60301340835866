import List from "@mui/material/List"
import { styled } from "@mui/material/styles"
import clsx from "clsx"
import FuseNavItem from "../FuseNavItem"
const debug = process.env.NODE_ENV === "development" && false

const StyledList = styled(List)(({ theme }) => ({
	"& .fuse-list-item": {
		"&:hover": {
			backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.05)" : "rgba(0,0,0,.04)",
		},
		"&:focus:not(.active)": {
			backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.06)" : "rgba(0,0,0,.05)",
		},
	},
	"& .fuse-list-item-text": {
		margin: 0,
	},
	"& .fuse-list-item-text-primary": {
		lineHeight: "20px",
	},
	"&.active-square-list": {
		"& .fuse-list-item, & .active.fuse-list-item": {
			width: "100%",
			borderRadius: "0",
		},
	},
	"&.dense": {
		"& .fuse-list-item": {
			paddingTop: 0,
			paddingBottom: 0,
			height: 32,
		},
	},
}))

function FuseNavVerticalLayout1(props) {
	debug && console.log("<FuseNavVerticalLayout1> Constructor: ", { props })
	const { navigation, layout, active, dense, className, onItemClick } = props

	function handleItemClick(item) {
		debug && console.log("<FuseNavVerticalLayout1> handleItemClick: ", { item })
		onItemClick?.(item)
	}

	return (
		<StyledList className={clsx("navigation whitespace-nowrap px-12 py-0", `active-${active}-list`, dense && "dense", className)}>
			{navigation.map((_item) => (
				<FuseNavItem key={_item.id} type={`vertical-${_item.type}`} item={_item} nestedLevel={0} onItemClick={handleItemClick} />
			))}
		</StyledList>
	)
}

export default FuseNavVerticalLayout1
