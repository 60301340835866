import { styled } from "@mui/material/styles"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import Typography from "@mui/material/Typography"
import format from "date-fns/format"
import { Box } from "@mui/system"
import { useParams } from "react-router-dom"
import FuseSvgIcon from "@fuse/core/FuseSvgIcon"
import NavLinkAdapter from "@fuse/core/NavLinkAdapter"
import { selectUser } from "app/store/userSlice"
import { useSelector } from "react-redux"
import ContactAvatar from "../../ContactAvatar"

const StyledListItem = styled(ListItem)(({ theme, active }) => ({
	"&.active": {
		backgroundColor: theme.palette.background.default,
	},
}))

function ChatListItem(props) {
	const { chatFound, chat } = props
	const user = useSelector(selectUser)
	//console.log("<ChatListItem>", { chatFound }, { chat })
	const routeParams = useParams()

	return (
		<StyledListItem button className="px-32 py-12 min-h-80" active={routeParams.id === chat.id ? 1 : 0} component={NavLinkAdapter} to={`${chat.id}`} end activeClassName="active">
			{/*<ContactAvatar chat={user} />*/}

			<ListItemText
				classes={{
					root: "min-w-px px-16",
					primary: "font-medium text-14 truncate",
					secondary: "font-light text-12 truncate",
				}}
				primary={chat.title}
				//secondary={chat ? chat.lastMessage : chat.about}
				secondary={chat.id}
			/>

			{chat.contactId && (
				<div className="flex flex-col justify-center items-end">
					{chat?.lastMessageAt && (
						<Typography className="whitespace-nowrap mb-8 font-medium text-12" color="text.secondary">
							{format(new Date(chat.lastMessageAt), "PP")}
						</Typography>
					)}
					<div className="items-center">
						{/*chat.muted && (
							<FuseSvgIcon size={20} color="disabled">
								heroicons-solid:volume-off
							</FuseSvgIcon>
						)*/}
						{/*Boolean(chat.unreadCount) && (
							<Box
								sx={{
									backgroundColor: "secondary.main",
									color: "secondary.contrastText",
								}}
								className="flex items-center justify-center min-w-20 h-20 rounded-full font-medium text-10 text-center">
								{chat.unreadCount}
							</Box>
						)*/}
					</div>
				</div>
			)}
		</StyledListItem>
	)
}

export default ChatListItem
